import React from 'react';
import { StyleProps } from './types';

interface HeadingProps extends StyleProps {
    text: string;
    headingLevel?: number;
}

const Heading: React.FC<HeadingProps> = ({
    className = '',
    headingLevel = 2,
    style,
    text,
    children,
}) => {
    const headingCSS = `default-heading ${className}`;
    switch (headingLevel) {
        case 1:
            return (
                <h1 className={headingCSS} style={style}>
                    {children || text}
                </h1>
            );
        case 2:
            return (
                <h2 className={headingCSS} style={style}>
                    {children || text}
                </h2>
            );
        case 3:
            return (
                <h3 className={headingCSS} style={style}>
                    {children || text}
                </h3>
            );
        case 4:
            return (
                <h4 className={headingCSS} style={style}>
                    {children || text}
                </h4>
            );
        case 5:
            return (
                <h5 className={headingCSS} style={style}>
                    {children || text}
                </h5>
            );
        case 6:
            return (
                <h6 className={headingCSS} style={style}>
                    {children || text}
                </h6>
            );
        default:
            return (
                <h2 className={headingCSS} style={style}>
                    {children || text}
                </h2>
            );
    }
};

export default Heading;
