import React from 'react';
import { Header, Footer, ErrorBoundary } from './';
import ScreenWidthProvider from '../utils/screenSize';
import './layout.css';

const Layout: React.FC<{ children: React.ReactNode | React.ReactNode[] }> = ({
    children,
}) => (
    <ErrorBoundary>
        <ScreenWidthProvider>
            <div className="flex flex-col h-screen">
                <Header />
                <main className="font-body text-ss-blue-900 flex-grow">
                    {children}
                </main>
                <Footer />
            </div>
        </ScreenWidthProvider>
    </ErrorBoundary>
);

export default Layout;
