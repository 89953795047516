import React from 'react';
import { StyleWithChildrenProps } from './types';

const Section: React.FC<StyleWithChildrenProps> = ({
    children,
    className = '',
    style,
}) => (
    <section className={`${className} default-page-section`} style={style}>
        {children}
    </section>
);

export default Section;
