import React, { createContext, useContext, useEffect, useState } from 'react';

import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../tailwind.config.js';

const fullConfig = resolveConfig(tailwindConfig);

export type ScreenWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

// tailwind css screen breakpoints
type TailwindScreenWidths = Exclude<ScreenWidth, 'xs'>;

export type ScreenBreakpoints = {
    [key in TailwindScreenWidths]: number;
};

let tailwindBreakpoints = {} as ScreenBreakpoints;
const entries: [string, string][] = Object.entries(fullConfig.theme.screens);
for (const [key, value] of entries) {
    tailwindBreakpoints[key as TailwindScreenWidths] = Number(
        value.replace('px', '')
    );
}
export const breakpoints = tailwindBreakpoints;

const ScreenWidthCtx = createContext<ScreenWidth>('xs');

const ScreenWidthProvider = ({ children }: { children?: React.ReactChild }) => {
    const [screenWidth, setScreenWidth] = useState<ScreenWidth>('xs');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < breakpoints.sm && screenWidth !== 'xs') {
                setScreenWidth('xs');
            } else if (
                window.innerWidth >= breakpoints.sm &&
                window.innerWidth < breakpoints.md &&
                screenWidth !== 'sm'
            ) {
                setScreenWidth('sm');
            } else if (
                window.innerWidth >= breakpoints.md &&
                window.innerWidth < breakpoints.lg &&
                screenWidth !== 'md'
            ) {
                setScreenWidth('md');
            } else if (
                window.innerWidth >= breakpoints.lg &&
                window.innerWidth < breakpoints.xl &&
                screenWidth !== 'lg'
            ) {
                setScreenWidth('lg');
            } else if (
                window.innerWidth >= breakpoints.xl &&
                screenWidth !== 'xl'
            ) {
                setScreenWidth('xl');
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidth]);

    return (
        <ScreenWidthCtx.Provider value={screenWidth}>
            {children}
        </ScreenWidthCtx.Provider>
    );
};

export default ScreenWidthProvider;
export const useScreenWidth = () => useContext(ScreenWidthCtx);
