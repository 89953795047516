import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Section, Container, Row, Column, Heading } from '../ui';
import { Button } from '../';
import { useScreenWidth } from '../../utils/screenSize';
import { StyleWithChildrenProps } from '../ui/types';

const DisputeSection: React.FC<
    StyleWithChildrenProps & {
        bullets?: string[];
        headline?: string;
        source?: string;
        subheadline?: string;
        buttonText?: string;
    }
> = ({
    className,
    style,
    headline = '4 in 5 credit reports contain an error.  Does yours?',
    source = 'CBS News',
    subheadline = '',
    bullets = [
        `ScoreShuttle gives you the power to dispute report errors in one click.`,
        `AI smarts help you identify potential problem areas.`,
        `Spot an error? Click to launch ScoreShuttle's Automated Dispute Cycle.`,
        `Anytime an error is removed, you could see an uptick in your scores.`,
    ],
    buttonText = 'ACCESS MY FREE CREDIT REPORT',
}) => {
    const data = useStaticQuery(graphql`
        query Images_dispute_section {
            mobileDispute: file(relativePath: { eq: "mobile-dispute.svg" }) {
                publicURL
            }
        }
    `);
    const screenWidth = useScreenWidth();

    return (
        <Section className={className} style={style}>
            <Container>
                <Row className="flex">
                    <Column className="w-full order-2 lg:ml-24">
                        <div className="container-narrow text-center lg:text-left">
                            <Heading
                                className="text-center lg:text-left lg:mx-0 mb-0"
                                text={headline}
                            />
                            <p className="text-ss-purple-700 font-light sm:text-xl md:font-thin my-5">
                                Source:{' '}
                                <cite className="not-italic">{source}</cite>
                            </p>
                            <p className="font-light sm:text-xl sm:font-thin">
                                {subheadline}
                            </p>
                            <ul className="font-light sm:font-thin sm:text-xl text-left list-disc pl-5 mb-5">
                                {bullets.map((listItem, index) => (
                                    <li key={index} className="my-4 md:my-3">
                                        {listItem}
                                    </li>
                                ))}
                            </ul>
                            <Button
                                theme="purple"
                                onClick={() =>
                                    window.open(
                                        'https://app.scoreshuttle.com/setup'
                                    )
                                }
                            >
                                {buttonText}
                            </Button>
                        </div>
                    </Column>
                    {(screenWidth === 'lg' || screenWidth === 'xl') && (
                        <Column className="flex-none w-auto">
                            <img
                                src={data.mobileDispute.publicURL}
                                alt="ScoreShuttle's mobile dispute page"
                            />
                        </Column>
                    )}
                </Row>
            </Container>
        </Section>
    );
};

export default DisputeSection;
