import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Container, Heading } from '../ui';
import { FlipCard } from '../';

const cardTypes = [
    'disputePower',
    'monitoring',
    'creditCamp',
    'scoreSimulator',
    'idTheft',
    'customerSupport',
] as const;

interface Props {
    title?: string;
    cardVisibility?: {
        [key in typeof cardTypes[number]]?: boolean;
    };
}
interface Card {
    title: string;
    img: string;
    alt: string;
    description: string;
    flag?: string;
}
const ServicesSection: React.FC<Props> = ({
    title = 'How to use ScoreShuttle to increase your credit scores',
    cardVisibility = {},
}) => {
    cardVisibility = {
        disputePower: true,
        monitoring: true,
        creditCamp: true,
        scoreSimulator: true,
        idTheft: true,
        customerSupport: true,
        ...cardVisibility,
    };
    const data = useStaticQuery(graphql`
        query Images_services_section {
            iconCamp: file(
                relativePath: { eq: "icons-services/creditcamp_logo.png" }
            ) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            iconClick: file(relativePath: { eq: "icons-services/click.svg" }) {
                publicURL
            }
            iconLocked: file(
                relativePath: { eq: "icons-services/locked.svg" }
            ) {
                publicURL
            }
            iconReport: file(
                relativePath: { eq: "icons-services/report.svg" }
            ) {
                publicURL
            }
            iconRising: file(
                relativePath: { eq: "icons-services/rising.svg" }
            ) {
                publicURL
            }
            iconSupport: file(
                relativePath: { eq: "icons-services/support.svg" }
            ) {
                publicURL
            }
        }
    `);

    const cardData: { [key in typeof cardTypes[number]]: Card } = {
        disputePower: {
            title: 'Digital Dispute Power',
            img: data.iconClick.publicURL,
            alt: 'click icon',
            description:
                "'Disputing' is a time-tested strategy used to challenge errors that may have led to a damaging derogatory mark on your credit report.  As a ScoreShuttle user, the software highlights potential problem areas for your review. If you believe an item contains an inaccuracy, simply click to launch ScoreShuttle’s fully automated dispute cycle to challenge the issue with all 3 credit bureaus.",
            flag: 'Most Popular',
        },
        monitoring: {
            title: '3 Bureau Credit Monitoring + Alerts',
            img: data.iconReport.publicURL,
            alt: 'report icon',
            description:
                'Credit scores can fluctuate from one bureau to the next.  As a premium software user, you’ll gain access to your monthly Experian, TransUnion, and Equifax credit reports and scores. ScoreShuttle monitors all 3 for inquiries, delinquencies, and any changes that may occur. If an update or red flag is detected, ScoreShuttle will notify you immediately.',
        },
        creditCamp: {
            title: 'Credit Camp',
            img: data.iconCamp.childImageSharp,
            alt: 'credit camp icon',
            description:
                'Get the know-how needed to increase your scores in Credit Camp! This guided e-learning series contains 12 bite-sized tutorials revealing a galaxy’s worth of credit-enhancing secrets and solutions to help you actively improve your scores.',
        },
        scoreSimulator: {
            title: 'Score Simulator',
            img: data.iconRising.publicURL,
            alt: 'increasing bar graph icon',
            description:
                'See how certain financial decisions could help you improve your credit using the score simulator tool.',
        },
        idTheft: {
            title: 'ID Theft Protection',
            img: data.iconLocked.publicURL,
            alt: 'padlock icon',
            description:
                "Protect your progress with ScoreShuttle's suite of identity theft protection features and 256-bit security encryption.",
        },
        customerSupport: {
            title: 'Experian 24/7 Identity Support',
            img: data.iconSupport.publicURL,
            alt: 'customer support icon',
            description:
                'As a trusted partner of Experian, premium ScoreShuttle users receive 24/7 access to Experian associates trained to answer your identity theft questions and promptly address any issues if/when they occur.',
        },
    };

    return (
        <Section>
            <Container className="px-0 lg:container lg:default-container-spacing">
                <Heading
                    className="container-narrow text-center"
                    text={title}
                />
                <ul className="flex flex-wrap" role="tablist" tabIndex={0}>
                    {cardTypes
                        .reduce((res: Card[], type) => {
                            if (cardVisibility[type]) {
                                res.push(cardData[type]);
                            }
                            return res;
                        }, [])
                        .map(({ title, img, alt, description, flag }) => (
                            <FlipCard
                                key={title}
                                title={title}
                                description={description}
                                alt={alt}
                                img={img}
                                flag={flag}
                            />
                        ))}
                </ul>
            </Container>
        </Section>
    );
};

export default ServicesSection;
