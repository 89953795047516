import React from 'react';

const removeExtraSpaces = (str: string) =>
    str.replace(/[^\S\r\n]{2,}/g, ' ').trim();

/**
 * @param backgroundColor if passing a tailwind color, it must be prefixed with 'text-'
 */
const WaveDivider: React.FC<{
    foregroundColor?: string;
    backgroundColor?: string;
    flip?: 'x' | 'y';
    inset?: 'bottom' | 'top';
}> = ({
    foregroundColor = 'bg-white',
    backgroundColor = 'text-ss-blue-100',
    flip,
    inset,
}) => {
    const flipClasses = {
        x: '-scaleX-1',
        y: '-scaleY-1',
    };

    let tailwindForegroundColor;
    let tailwindBackgroundColor;
    let cssForegroundColor;
    let cssBackgroundColor;

    foregroundColor.match('bg')
        ? (tailwindForegroundColor = foregroundColor)
        : (cssForegroundColor = foregroundColor);

    backgroundColor.match('text')
        ? (tailwindBackgroundColor = `${backgroundColor} fill-current`)
        : (cssBackgroundColor = backgroundColor);

    return (
        <svg
            width="100%"
            height="100px"
            viewBox="0 0 1440 100"
            version="1.1"
            preserveAspectRatio="none"
            className={removeExtraSpaces(
                `${tailwindForegroundColor} ${flip ? flipClasses[flip] : ''} ${
                    inset === 'bottom'
                        ? 'absolute bottom-0'
                        : inset === 'top'
                        ? 'absolute top-0'
                        : ''
                } h-16 sm:h-20 lg:h-24`
            )}
            style={{ backgroundColor: cssForegroundColor }}
        >
            <g
                className={tailwindBackgroundColor}
                style={{ fill: cssBackgroundColor }}
            >
                <path d="M0,37.7360404 C187.883001,-17.9509926 449.0187,-12.005056 783.407095,55.5738504 C1117.79549,123.152757 1336.65979,113.401112 1440,26.3189162 L1440,100 L0,100 L0,37.7360404 Z"></path>
            </g>
        </svg>
    );
};

export default WaveDivider;
