import React from 'react';

const themeClasses = {
    blue: 'bg-blue-500 hover:bg-blue-600 text-white shadow-blue',
    purple:
        'bg-ss-purple-700 hover:bg-ss-purple-600 text-white shadow-ss-purple',
};
interface ButtonProps {
    theme: 'blue' | 'purple';
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    htmlType?: 'button' | 'submit';
    disabled?: boolean;
    loading?: boolean;
}
const Button: React.FC<ButtonProps> = ({
    theme = 'blue',
    htmlType = 'button',
    children,
    onClick,
    disabled = false,
    loading = false,
}) => {
    return (
        <button
            className={`${
                loading ? 'spinner ' : ''
            }text-sm px-6 py-4 rounded focus:outline-none focus:shadow-outline mx-auto ${
                themeClasses[theme]
            }`}
            type={htmlType}
            onClick={onClick ? onClick : undefined}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;
