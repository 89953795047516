import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';

const Header: React.FC = () => {
    const logo = useStaticQuery(graphql`
        query Logo {
            file(relativePath: { eq: "scoreshuttle-logo-full.svg" }) {
                publicURL
            }
        }
    `);
    return (
        <header className={`w-screen bg-white`}>
            <div className={`container mx-auto px-8 py-4`}>
                <a href="https://www.scoreshuttle.com" target="__blank">
                    <img
                        className={
                            'w-40 sm:w-48 md:w-56 lg:w-auto inline-block'
                        }
                        src={logo.file.publicURL}
                        alt="go to the ScoreShuttle home page"
                    />
                </a>
            </div>
        </header>
    );
};

export default Header;
