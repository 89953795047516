import React from 'react';

import { Section, Container, Row, Heading } from './ui';
import { Button } from './';

const FullwidthCTA: React.FC<{ headline?: string; buttonText?: string }> = ({
    headline = 'Ready to take your credit scores to new heights?',
    buttonText = 'GET STARTED FOR FREE',
}) => {
    return (
        <Section>
            <Container className="mb-16">
                <Row className="text-center container-narrow">
                    <Heading className="text-center" text={headline} />
                    <Button
                        theme="purple"
                        onClick={() =>
                            window.open('https://app.scoreshuttle.com/setup')
                        }
                    >
                        {buttonText}
                    </Button>
                </Row>
            </Container>
        </Section>
    );
};

export default FullwidthCTA;
