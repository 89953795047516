import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookF,
    faInstagram,
    faTwitter,
    faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';

import { WaveDivider } from './';
import { Row } from './ui';

const Footer: React.FC = () => (
    <footer className="flex-shrink-0 font-body bg-ss-footer relative text-white">
        <WaveDivider
            foregroundColor="bg-white"
            backgroundColor="text-ss-footer"
        />
        <div className="container mx-auto px-8 pt-20 sm:flex sm:flex-wrap">
            <Row className="mb-8 text-sm">
                <a
                    className="focus:outline-none focus:text-gray-300"
                    href="https://www.scoreshuttle.com/privacy/"
                >
                    Privacy Policy
                </a>
            </Row>
            <Row className="text-sm">
                <p>
                    The ScoreShuttle and ScoreShuttle.com names are registered
                    trademarks of FinnTekk, Inc. All Rights Reserved.
                </p>
            </Row>
            <Row>
                <ul className="flex pt-6 pb-8">
                    {[
                        {
                            name: 'Facebook',
                            href: 'http://facebook.com/scoreshuttle',
                            icon: faFacebookF,
                            backgroundColor: 'bg-facebook',
                        },
                        {
                            name: 'Instagram',
                            href: 'http://instagram.com/scoreshuttle',
                            icon: faInstagram,
                            backgroundColor: 'bg-instagram',
                        },
                        {
                            name: 'Twitter',
                            href: 'http://twitter.com/scoreshuttle',
                            icon: faTwitter,
                            backgroundColor: 'bg-twitter',
                        },
                        {
                            name: 'LinkedIn',
                            href: 'http://linkedin.com/company/scoreshuttle',
                            icon: faLinkedinIn,
                            backgroundColor: 'bg-linkedin',
                        },
                    ].map(socialLink => (
                        <li key={socialLink.name}>
                            <a
                                href={socialLink.href}
                                title={`Follow on ${socialLink.name}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`${socialLink.backgroundColor} w-8 h-8 rounded flex justify-center items-center mr-3 focus:outline-none focus:shadow-outline`}
                            >
                                <FontAwesomeIcon icon={socialLink.icon} />
                            </a>
                        </li>
                    ))}
                </ul>
            </Row>
        </div>
    </footer>
);

export default Footer;
