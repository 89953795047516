import React from 'react';
import { StyleWithChildrenProps } from './types';

const Column: React.FC<StyleWithChildrenProps> = ({
    children,
    className = '',
    style,
}) => (
    <div className={`default-column ${className}`} style={style}>
        {children}
    </div>
);

export default Column;
