module.exports = {
    theme: {
        extend: {
            boxShadow: theme => {
                return {
                    blue:
                        '0 1px 3px 0 rgba(56, 157, 243, .7), 0 1px 2px 0 rgba(56, 157, 243, .06)',
                    'ss-purple':
                        '0 1px 3px 0 rgba(59, 59, 255, .7), 0 1px 2px 0 rgba(59, 59, 255, .06)',
                    'focus-inner': `inset 0 0 0 3px ${theme('colors.focus')}`,
                };
            },
            colors: {
                'ss-footer': '#0b1720',
                'ss-purple': {
                    '100': '#e3e3ff',
                    '200': '#c7c7ff',
                    '300': '#ababff',
                    '400': '#8f8fff',
                    '500': '#7373ff',
                    '600': '#5757ff',
                    '700': '#3b3bff',
                    '800': '#2d2dbc',
                    '900': '#2a2a83',
                },
                'ss-blue': {
                    '100': '#e9f9ff',
                    '200': '#b9e6fa',
                    '300': '#89cef0',
                    '400': '#5bafe3',
                    '500': '#378ecc',
                    '600': '#2371b0',
                    '700': '#1f5891',
                    '800': '#183f6b',
                    '900': '#0e2440',
                },
                facebook: '#3b5998',
                instagram: '#ea2c59',
                linkedin: '#00aced',
                twitter: '#007bb6',
                focus: 'rgba(66,153,225,0.5)',
            },
            fill: theme => {
                return {
                    'ss-purple': theme('colors[ss-purple].700'),
                };
            },
            fontFamily: {
                heading: ['IBM Plex Serif', 'serif'],
                body: ['Roboto', 'sans-serif'],
            },
            fontSize: {
                '2xs': '.5rem',
            },
            height: {
                72: '18rem',
                80: '20rem',
                88: '22rem',
                96: '24rem',
            },
            maxWidth: {
                '1/4': '25%',
                '1/2': '50%',
                '3/4': '75%',
            },
            minHeight: {
                72: '18rem',
                80: '20rem',
                88: '22rem',
                96: '24rem',
            },
            minWidth: {
                '1/4': '25%',
                '1/2': '50%',
                '3/4': '75%',
            },
        },
    },
    variants: {},
    plugins: [require('tailwindcss-spinner')()],
};
