import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { useSpring, animated as a } from 'react-spring';
import { css, jsx } from '@emotion/core';

import { useScreenWidth, breakpoints } from '../utils/screenSize';

// h-80 = 20rem and h-96 = 24rem
const flipCardCSS = css`
    @media screen and (min-width: ${breakpoints.lg}px) {
        min-height: 20rem;
    }
    @media screen and (min-width: ${breakpoints.xl}px) {
        min-height: 24rem;
    }
`;

const flagContainerCSS = css`
    top: -2px;
    right: 0;

    @media screen and (min-width: ${breakpoints.sm}px) {
        right: 5rem;
    }

    @media screen and (min-width: ${breakpoints.lg}px) {
        right: 1rem;
    }
`;

const dividePhraseInHalf = (phrase: string) => {
    var middle = Math.floor(phrase.length / 2);
    var before = phrase.lastIndexOf(' ', middle);
    var after = phrase.indexOf(' ', middle + 1);

    if (before == -1 || (after != -1 && middle - before >= after - middle)) {
        middle = after;
    } else {
        middle = before;
    }

    return [phrase.substr(0, middle), phrase.substr(middle + 1)];
};

interface FlipCardProps {
    title: string;
    img: any;
    alt: string;
    description: string;
    flag?: string;
}

const FlipCard: React.FC<FlipCardProps> = ({
    title = '',
    img = '',
    alt = '',
    description = '',
    flag: flagText = '',
}) => {
    const [flipped, setFlipped] = useState(false);

    const screenWidth = useScreenWidth();
    const isDesktopDisplay = screenWidth === 'lg' || screenWidth === 'xl';

    const { transform, opacity } = useSpring({
        opacity: flipped ? 1 : 0,
        transform: `perspective(1000px) rotateY(${flipped ? 180 : 0}deg)`,
        config: { mass: 5, tension: 500, friction: 80 },
    });

    const splitTitle = dividePhraseInHalf(title);

    const summaryDisplay = (
        <div className="flex lg:flex-col items-center h-full px-3 py-5 sm:py-3 lg:py-8 xl:py-10 relative">
            <span className="order-2 text-center flex-auto">
                {isDesktopDisplay ? (
                    <>
                        <strong className="sm:text-lg lg:text-xl">
                            {splitTitle[0]}
                        </strong>
                        <br />
                        <strong className="sm:text-lg lg:text-xl">
                            {splitTitle[1]}
                        </strong>
                    </>
                ) : (
                    <strong className="sm:text-lg lg:text-xl">{title}</strong>
                )}
            </span>
            <FontAwesomeIcon
                className="order-3 flex-none lg:flex-auto text-5xl text-gray-500 mr-3 lg:mx-auto w-12"
                icon={faPlusCircle}
            />
            <div className="order-1 flex-none lg:flex-auto mr-5 ml-3 md:ml-6 lg:mx-auto">
                <div className="w-16 h-16 lg:w-20 lg:h-20 xl:w-24 xl:h-24">
                    {typeof img === 'string' ? (
                        <img src={img} alt={alt} />
                    ) : (
                        <Img fluid={img.fluid} alt={alt} />
                    )}
                </div>
            </div>
            {flagText && (
                <div
                    className="absolute flex sm:block sm:w-16"
                    css={flagContainerCSS}
                >
                    <p className="bg-ss-purple-700 text-2xs text-white text-center py-1 sm:py-2 px-4 sm:px-2 h-6 sm:h-full order-2">
                        {flagText}
                    </p>
                    {screenWidth === 'xs' ? (
                        <svg
                            width="10px"
                            viewBox="0 0 10 10"
                            preserveAspectRatio="none"
                            className="fill-ss-purple h-6 order-1"
                        >
                            <polygon points="10 0, 0 0, 10 5, 0 10, 10 10" />
                        </svg>
                    ) : (
                        <svg
                            width="100%"
                            height="10px"
                            viewBox="0 0 10 10"
                            preserveAspectRatio="none"
                            className="fill-ss-purple"
                        >
                            <polygon points="0 0, 0 10, 5 0, 10 10, 10 0" />
                        </svg>
                    )}
                </div>
            )}
        </div>
    );

    const detailsDisplay = (
        <div className="h-full p-6 text-center">
            <span className="text-lg">
                <strong className="">{title}</strong>
            </span>
            <p className="leading-relaxed mt-4">{description}</p>
        </div>
    );

    return (
        <li
            className="w-full lg:w-1/2 xl:w-1/3"
            css={flipCardCSS}
            role="presentation"
        >
            <button
                className={`${
                    flipped ? 'bg-ss-blue-100 ' : ''
                }w-full h-full relative border border-gray-300 focus:outline-none focus:shadow-focus-inner focus:border-focus`}
                onClick={() => setFlipped(prevState => !prevState)}
                aria-label={
                    flipped
                        ? `Hide information about ${title} power`
                        : `Display more information about ${title} power`
                }
            >
                <a.div
                    className="lg:h-80 xl:h-96 w-full static lg:absolute"
                    style={{
                        top: 0,
                        display: isDesktopDisplay
                            ? 'block'
                            : flipped
                            ? 'none'
                            : 'block',
                        opacity: opacity.interpolate(o => 1 - (o as number)),
                        transform,
                    }}
                >
                    {summaryDisplay}
                </a.div>
                <a.div
                    className="h-full"
                    style={{
                        display: flipped ? 'block' : 'none',
                        opacity,
                        transform: transform.interpolate(
                            t => `${t} rotateY(180deg)`
                        ),
                    }}
                >
                    {detailsDisplay}
                </a.div>
            </button>
        </li>
    );
};

export default FlipCard;
