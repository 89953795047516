import React from 'react';
import { StyleWithChildrenProps } from './types';

const Container: React.FC<StyleWithChildrenProps> = ({
    children,
    className = 'container',
    style,
}) => {
    const css = className.split(' ').reduce(
        (acc, cls) => {
            cls.includes('container')
                ? (acc.containerStyles = (
                      acc.containerStyles + ` ${cls}`
                  ).trim())
                : (acc.otherStyles = (acc.otherStyles + ` ${cls}`).trim());
            return acc;
        },
        { containerStyles: '', otherStyles: '' }
    );

    return (
        <div
            className={`default-container-spacing ${css.containerStyles ||
                'container'} ${css.otherStyles}`}
            style={style}
        >
            {children}
        </div>
    );
};

export default Container;
